export const WORDS = [
  'james',
  'steph',
  'freak',
  'lonzo',
  'keita',
  'larry',
  'bulls',
  'cousy',
  'dydek',
  'embid',
  'chris',
  'kings',
  'sloan',
  'miami',
  'dieng',
  'bucks',
  'hawks',
  'magic',
  'oneal',
  'smith',
  'garza',
  'hayes',
  'vogel',
  'vince',
  'allen',
  'gasol',
  'motta',
  'greer',
  'theus',
  'davis',
  'sikma',
  'spurs',
  'brown',
  'gregg',
  'steve',
  'riley',
  'kyrie',
  'jokic',
  'lowry',
  'blake',
  'andre',
  'demar',
  'devin',
  'adams',
  'tatum',
  'young',
  'green',
  'ariza',
  'bonga',
  'avery',
  'bruce',
  'burks',
  'clark',  
]
